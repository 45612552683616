import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { Observable, from, of, take } from 'rxjs';
import { AttributesComponent } from '../shared/components/attributes/attributes.component';
import { ProductAdderModel } from '../shared/models/productAdderModel';
import { ProductCardModel } from '../shared/models/productCardModel';
import { AttributeModel } from '../shared/models/shopping-cart-item';
import { CartService } from '../shared/services/cart.service';
import { WeighedProductComponent } from '../weighed-product/weighed-product.component';

@Component({
  selector: 'app-product-adder',
  templateUrl: './product-adder.component.html',
  styleUrls: ['./product-adder.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers:[ModalController]
})
export class ProductAdderComponent implements OnInit {

  constructor(
    private cd: ChangeDetectorRef,
    private cartService: CartService,
    private modalCtrl: ModalController) { }

  async ngOnInit() {
    this.cartService.getProductAdderObserver.subscribe(async productAdderModel => {
      this.processFurther(productAdderModel);
    });
  }

  private async processFurther(productAdderModel: ProductAdderModel) {
    if (productAdderModel.Mode === 'COUNTER' && productAdderModel.CartItemGuid) // just update the qty, suppress other dialogs like attributes and weight
    {
      const existingCartItem = this.cartService.getCartItemByGuid(productAdderModel.CartItemGuid);
      const attributesPriceInfluence = (existingCartItem ? existingCartItem.AttributesPriceInfluence : 0) || 0;
      this.cartService.addProduct(
        productAdderModel.Product.ProductId, 
        productAdderModel.Product.OrderQtyStep, 
        productAdderModel.Product.PriceInclVat + attributesPriceInfluence, 
        productAdderModel.Product.Ratio, 
        null,
        productAdderModel.Product.IsScale,
        true,
        existingCartItem.Attributes,
        productAdderModel.CartItemGuid).pipe(take(1)).subscribe();
    }
    else if (productAdderModel.Mode === 'MODIFY') {
      this.getFinalQuatity(productAdderModel).subscribe(finalQuantity =>{

        if (!finalQuantity)
          return;

        if (productAdderModel.Product.HasAttributes) {
          this.showAttributes(finalQuantity, productAdderModel.Product, productAdderModel.ProductImageElement);
        }
        else {
          this.cartService.addProduct(productAdderModel.Product.ProductId, finalQuantity, productAdderModel.Product.PriceInclVat, 
            productAdderModel.Product.Ratio, productAdderModel.ProductImageElement, productAdderModel.Product.IsScale).pipe(take(1)).subscribe();
        }
      });
    }

    this.cd.detectChanges();
  }

  private getFinalQuatity(productAdderModel: ProductAdderModel) : Observable<number>{
    if (productAdderModel.Product.IsScale) {
      return from(this.showWeighedProductModal(productAdderModel.Product).then((data)=>{
        if (data.data)
          return data.data.qty;
        else {
          return; //the modal was closed withoud returning a value
        }
      }));
    }
    else {
      return of(productAdderModel.Qty);
    }
  }

  private async showAttributes(qtyStep: number, product: ProductCardModel, imageElementRef: ElementRef | null) {
    const modal = await this.modalCtrl.create({
      component: AttributesComponent,
      componentProps: {
        productId: product.ProductId
      },
      cssClass: 'modal-fullscreen',
      keyboardClose: true,
      showBackdrop: true
    });

    modal.onDidDismiss().then(result => {
      const attributesArray: AttributeModel[] = [];
      if (result.data) {
        const keys = Object.keys(result.data);
        for (const prop of keys) {
          if (result.data[prop].Value)
            attributesArray.push({ Name: result.data[prop].Key, PriceAdjustment: result.data[prop].PriceAdjustment, Value: result.data[prop].Value })
        }
        const finalPrice = product.PriceInclVat + attributesArray.reduce((sum, current) => sum + current.PriceAdjustment, 0);
        this.cartService.addProduct(product.ProductId, qtyStep, finalPrice, product.Ratio, imageElementRef, product.IsScale, true, attributesArray).pipe(take(1)).subscribe();
      }
      
    });
    await modal.present();
  }

  private async showWeighedProductModal(product: ProductCardModel) {
    const modal = await this.modalCtrl.create({
      component: WeighedProductComponent,
      componentProps: {
        counterStep: product.OrderQtyStep,
        counterRangeMin: product.MinOrderQty,
        counterRangeMax: product.MaxOrderQty,
        price: product.PriceInclVat,
        ratio: product.Ratio,
        productImage: product.ProductImage,
        productName: product.ProductName
      },
      cssClass: 'modal-fullscreen',
      keyboardClose: true,
      showBackdrop: true
    });

    await modal.present();
    return await modal.onDidDismiss().then(data => { return data });
  }
}
