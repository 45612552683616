import { Routes } from '@angular/router';

export const routes: Routes = [

  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadComponent: () => import('./home/home.page').then((m) => m.HomePage),
  },
  { path: 'landing', loadComponent: () => import('./landing/landing.component').then(m => m.LandingComponent) },
  { path: 'menu', loadComponent: () => import('./menu/menu.component').then(m => m.MenuComponent) },
  { path: 'subcategories', loadComponent: () => import('./subcategories/subcategories.component').then(m => m.SubcategoriesComponent) },
  { path: 'cart', loadComponent: () => import('./cart/cart.component').then(m => m.CartComponent) },
  { path: 'itemdetails/:id', loadComponent: () => import('./itemdetails/itemdetails.component').then(m => m.ItemdetailsComponent) },
  { path: 'orderplaced', loadComponent: () => import('./orderplaced/orderplaced.component').then(m => m.OrderplacedComponent) },

];
