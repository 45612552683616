import { Component } from '@angular/core';
import {
  IonApp,
  IonRouterOutlet,
  NavController,
  Platform,
} from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { addIcons } from 'ionicons';
import {
  chevronForwardOutline,
  search,
  home,
  close,
  informationCircleOutline,
  addCircleOutline,
  ellipsisHorizontalOutline,
  fastFood,
  basket,
  trash,
  add,
  arrowBackOutline,
  resizeOutline,
} from 'ionicons/icons';
import { ProductAdderComponent } from './product-adder/product-adder.component';
import { Title } from '@angular/platform-browser';
import { StoreService } from './shared/services/store.service';
import { BroadcastMessageService } from './shared/services/broadcastmessage.service';
import { environment } from 'src/environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet, ProductAdderComponent],
})
export class AppComponent {
  constructor(
    private translate: TranslateService,
    private platform: Platform,
    private title: Title,
    private router: Router,
    private storeService: StoreService,
    private broadcastMessageService: BroadcastMessageService,
    private navCtrl: NavController,
    private swUpdate: SwUpdate
  ) {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }

    // if application is loaded as web application
    this.swUpdate.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_READY':
          this.swUpdate.activateUpdate().then(() => document.location.reload());
          break;
      }
      
    });

    addIcons({
      chevronForwardOutline,
      search,
      home,
      close,
      informationCircleOutline,
      addCircleOutline,
      ellipsisHorizontalOutline,
      fastFood,
      basket,
      trash,
      add,
      arrowBackOutline,
      resizeOutline,
    });

    this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        
        this.platform.ready().then(() => {
          this.checkForPWAUpdate();

          this.translate.setDefaultLang('nl');
          this.translate.use('nl');

          this.broadcastMessageService.broadcastChannel.onmessage = (event) => {
            const { type, payload } = event.data;
            if (type === 'RedirectToLanding') {
              this.navCtrl.navigateRoot(['/landing'], { replaceUrl: true });
            }
          };

          this.title.setTitle(
            this.storeService.merchant$.value?.StoreName || 'qOber'
          );

          document.documentElement.style.setProperty(
            '--ion-color-primary',
            '#c5000f'
          );
        });
      });
  }

  private checkForPWAUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate
        .checkForUpdate()
        .then(() => {
          console.log('Checking for updates...');
        })
        .catch((err) => {
          console.error('Error when checking for update', err);
        });
    }
  }
}
