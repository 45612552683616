import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter, withPreloading, PreloadAllModules, withComponentInputBinding, Router, ActivatedRoute } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injector, importProvidersFrom, isDevMode } from '@angular/core';
import { StoreService } from './app/shared/services/store.service';
import { firstValueFrom, of, concatMap } from 'rxjs';
import { environment } from './environments/environment';
import { register } from 'swiper/element';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LOCATION_INITIALIZED } from '@angular/common';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CatalogService } from './app/shared/services/catalog.service';
import { StoreDto } from './app/shared/interfaces/store.model';
import { BroadcastMessageService } from './app/shared/services/broadcastmessage.service';
import { provideServiceWorker } from '@angular/service-worker';

// register Swiper custom elements
register();

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function initializeApp(
  route: ActivatedRoute,
  router: Router,
  storeService: StoreService,
  catalogService: CatalogService,
  broadcastMessageService: BroadcastMessageService
) {
  if (environment.production) {
    if (location.protocol === 'http:') {
      window.location.href = location.href.replace('http', 'https');
    }
  }

  return (): Promise<any> => {
    const urlParams = new URLSearchParams(window.location.search);
    const qrCode = urlParams.get('qr') || urlParams.get('QR');
    const merchant = urlParams.get('m') || urlParams.get('M');

    // prevent multiple running instances in browser tabs
    broadcastMessageService.broadcastChannel.postMessage({ type: 'RedirectToLanding', payload: { } });

    if (!qrCode && !merchant) {
      if (!storeService.merchant$.value?.StoreId) {
        router.navigate(['landing']);
        return firstValueFrom(of(null));
      } 
      else {
        router.navigate(['home']);
        return firstValueFrom(of(storeService.merchant$.value));
      }
    }

    return firstValueFrom(storeService.retrieveStoreByQrCode(qrCode!).pipe(concatMap((storeDto: StoreDto)=>{ return catalogService.retrieveCatalog(storeDto.StoreGuid); }))).catch(
      (error) => {
        console.error('Kan applicatie niet opstarten. Fout: ' + error);
        router.navigate(['landing']);
        return firstValueFrom(of(null));
      }
    );
  };
}

export function initializeTranslateService(
  translate: TranslateService,
  injector: Injector
) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'nl';
      translate.setDefaultLang('nl');
      translate.use(langToSet).subscribe(() => {
      }, err => { }, () => {
        resolve(null);
      });
    });
  });
}


bootstrapApplication(AppComponent, {
  
  providers: [
    provideAnimations(),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular({ mode: 'md', swipeBackEnabled: false }),
    provideRouter(routes, withPreloading(PreloadAllModules), withComponentInputBinding()),
    provideHttpClient(),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [ActivatedRoute, Router, StoreService, CatalogService, BroadcastMessageService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTranslateService,
      multi: true,
      deps: [TranslateService, Injector],
    },
    importProvidersFrom(
      [
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient]
          }
        })
      ]
    ), provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
          }),
  ],
});
